import Topic from "./TopicClass";
import {Link, useSearchParams} from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axios";
import TopicNav from "./TopicNav";
import TopicTranslateView from "../topic_translate/TopicTranslateView";
import TopicTranslateList from "../topic_translate/TopicTranslateList";

function TopicView() {
    const [searchParams] = useSearchParams();
    const [topic, setTopic] = useState(new Topic());
    const [suggestions, setSuggestions] = useState([]);
    const [selectedParentSlug, setSelectedParentSlug] = useState('');
    const id = searchParams.get("id");

    const fetchTopic = async (topicId) => {
        try {
            const response = await axiosInstance.get(`/admin/topic/${topicId}`);
            const item = response.data;
            if (item) {
                return new Topic(
                    item.id,
                    item.slug,
                    item.slug_path,
                    item.type,
                    item.parent_id,
                    item.updated_at,
                    item.created_at
                );
            }
        } catch (error) {
            console.error("Failed to fetch topic:", error);
            return null;
        }
    };

    useEffect(() => {
        const loadTopic = async () => {
            const newTopic =  await fetchTopic(id)
            if (newTopic !== null){
                setTopic(newTopic);
                const parentTopic = await fetchTopic(newTopic.parent_id)
                setSelectedParentSlug(parentTopic.slug)
            }
        };
        if (id) {
            setSelectedParentSlug(null)
            setSuggestions([])
            loadTopic();
        }
    }, [id]);

    // Fetch available parent topics for the autocomplete functionality
    const fetchParentSuggestions = async (query) => {
        try {
            if (query.length > 2) {
                const response = await axiosInstance.get(`/admin/topic?slug=${query}`);
                setSuggestions(response.data);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error("Failed to fetch parent suggestions:", error);
        }
    };

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        setTopic((prevTopic) => ({
            ...prevTopic,
            [field]: value,
        }));
    };


    const handleParentChange = (e) => {
        const inputValue = e.target.value;
        setSelectedParentSlug(inputValue);
        if (inputValue.length === 0) {
            setTopic((prevTopic) => ({
                ...prevTopic,
                parent_id: null,
            }));
        } else {
            const parent = suggestions.find((suggestion) => suggestion.slug_path === inputValue);

            if (parent) {
                console.log(topic.id, parent.id);
                setSelectedParentSlug(parent.slug);
                setTopic((prevTopic) => ({
                    ...prevTopic,
                    parent_id: parent.id,
                }));
                setSuggestions([]);
            } else {
                fetchParentSuggestions(inputValue);
            }
        }
    };

    const onDeleteTopic = (deletedTopic) =>{
        setTopic(new Topic());
    }

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Topic Details</h5>
                    <TopicNav topic={topic} editable={true} setTopicAfterCreate={setTopic} setTopicAfterUpdate={setTopic} setTopicAfterDelete={onDeleteTopic}/>

                    {/* Slug */}
                    <div className="mb-3">
                        <label htmlFor="slug" className="form-label">Slug</label>
                        <input
                            type="text"
                            className="form-control"
                            id="slug"
                            value={topic.slug || ''}
                            onChange={(e) => handleInputChange(e, 'slug')}
                        />
                    </div>

                    {/* Type */}
                    <div className="mb-3">
                        <label htmlFor="type" className="form-label">Type</label>
                        <select
                            className="form-select"
                            id="type"
                            value={topic.type || ''}
                            onChange={(e) => handleInputChange(e, 'type')}
                        >
                            <option value={"publication"}>Publication</option>
                            <option value={"section"}>Section</option>
                            <option value={"page"}>Page</option>
                        </select>
                    </div>

                    {/* Parent ID with Autocomplete */}
                    <div className="mb-3">
                        <label htmlFor="parent_id" className="form-label">Parent</label>
                        <input
                            type="text"
                            className="form-control"
                            id="parent_id"
                            value={selectedParentSlug || ''}
                            onChange={(e) => handleParentChange(e)}
                            list="parentSuggestions"
                        />
                        {topic.parent_id && <Link to={`/admin/topic/view?id=${topic.parent_id}`}>View Parent</Link>}
                        <datalist id="parentSuggestions">
                            {suggestions.map((suggestion) => (
                                <option
                                    key={suggestion.id}
                                    value={suggestion.slug_path}>
                                    {suggestion.slug_path}
                                </option>
                            ))}
                        </datalist>
                    </div>



                </div>
                <div className="card-footer">
                    {/* Slug Path */}
                    <div className="mb-3">
                        <label htmlFor="slug_path" className="form-label">Slug Path</label>
                        <input
                            type="text"
                            className="form-control"
                            id="slug_path"
                            value={topic.slug_path || ''}
                            readOnly
                        />
                    </div>
                    <div className="d-flex">
                        {/* Updated At */}
                        <div className="mb-3">
                            <label htmlFor="updated_at" className="form-label">Updated At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="updated_at"
                                value={topic.updated_at || ''}
                                readOnly
                            />
                        </div>

                        {/* Created At */}
                        <div className="mb-3">
                            <label htmlFor="created_at" className="form-label">Created At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="created_at"
                                value={topic.created_at || ''}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
            {topic.id &&
                <TopicTranslateList entity_id={topic.id} />
            }

        </div>
    );
}

export default TopicView;
