import React, {useEffect} from "react";
import Markdown from "react-markdown";
import CopyLinkButton from "../components/CopyLinkButton";
import tgBot from "../../tgBot";

function TopicContent({ topic }) {
    const { data } = topic;
    useEffect(() => {
        window.Telegram.WebApp.BackButton.hide();
    }, [topic]);
    return (
        <div>
            <div className="d-flex">
                <h2>{topic.data.title}</h2>
                <div className="">
                    <CopyLinkButton height={'50%'} link={`t.me/${tgBot.username}/topic?startapp=topic_${topic.topic.slug_path.replaceAll("/","_")}`}/>
                </div>
            </div>

            <div className="markdown-content">
                {data.content ? (
                    <Markdown>{data.content}</Markdown>
                ) : (
                    <p>No content available.</p>
                )}
            </div>
        </div>

    );
}

export default TopicContent;
