import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios";
import {Link, useParams} from "react-router-dom";
import Topic from "./TopicClass"
import {toast} from "react-toastify";



function TopicNav({topic = new Topic, editable = Boolean, setTopicAfterCreate, setTopicAfterUpdate, setTopicAfterDelete}) {

    const createTopic = async () =>{
        try {
            const response = await axiosInstance.post(`/admin/topic`, topic);
            setTopicAfterCreate(response.data)
            toast.success("Topic is created successfully");
        } catch (error) {
            toast.error("Failed to fetch create topic: "+ JSON.stringify(error.response.data));
            console.error("Failed to fetch create topic:", error);
        }
    }

    const updateTopic = async () =>{
        try {
            const response = await axiosInstance.patch(`/admin/topic/${topic.id}`, topic);
            setTopicAfterUpdate(response.data)
            toast.success("Topic is updated successfully");

        } catch (error) {
            toast.error("Failed to fetch update topic: "+ JSON.stringify(error.response.data));

            console.error("Failed to fetch update topic:", error);
        }
    }

    const deleteTopic = async () =>{
        try {
            const response = await axiosInstance.delete(`/admin/topic/${topic.id}`);
            setTopicAfterDelete(topic)
            toast.success("Topic is deleted successfully");

        } catch (error) {
            toast.error("Failed to fetch delete topic: "+ JSON.stringify(error.response.data));
            console.error("Failed to fetch delete topic:", error);
        }
    }

    const onClickSaveButton = async () => {
        if(topic.id){
            await updateTopic()
        }else{
            await createTopic()
        }
    }

    const onClickDeleteButton = async () => {
        await deleteTopic()
    }


    return (
        <div className="d-flex w-100">
            {!editable &&
                <Link to={'/admin/topic/view'} className="btn btn-outline-warning rounded-0" >New</Link>
            }
            {topic && !editable &&
                <Link to={`/admin/topic/view?id=${topic.id}`} className="btn btn-outline-warning rounded-0" >View</Link>
            }
            {topic && editable &&
                <button className="btn btn-outline-warning rounded-0" onClick={onClickSaveButton}>Save</button>
            }
            {topic &&
                <button className="btn btn-outline-warning rounded-0" onClick={onClickDeleteButton}>Delete</button>
            }
        </div>
    );
}

export default TopicNav;
