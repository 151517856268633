import React, {useEffect, useState} from "react";
import axiosInstance from "../../../../axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";


const BotAdminPanel = () => {
    const [userBotAccess, setUserBotAccess] = useState(null)

    useEffect(() => {
        const fetchUserBotAccess = async () => {
            try {
                const response = await axiosInstance.get(`/user/botAccess`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setUserBotAccess(response.data)
            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch user accesses.");
            }
        }
        fetchUserBotAccess();
    }, []);

    return (userBotAccess &&
            <div className="container my-4">
                <div className="row m-0">
                    {userBotAccess.can_edit_topics &&
                        <Link to={"/admin/topic"} className="bg-secondary col-3 align-items-center rounded-2">
                            Topics
                        </Link>
                    }
                </div>
            </div>
    )
}

export default BotAdminPanel;