import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios";
import {Link, useParams} from "react-router-dom";
import TopicTranslate from "./TopicTranslateClass";
import {toast} from "react-toastify";



function TopicTranslateNav({topicTranslate = new TopicTranslate, editable = Boolean, setTopicTranslateAfterCreate, setTopicTranslateAfterUpdate, setTopicTranslateAfterDelete}) {

    const createTopicTranslate = async () =>{
        try {
            const response = await axiosInstance.post(`/admin/topicTranslate`, topicTranslate);
            setTopicTranslateAfterCreate(response.data)
            toast.success("Topic Translate is created successfully");
        } catch (error) {
            toast.error("Failed to fetch create topic translate: "+ JSON.stringify(error.response.data));
            console.error("Failed to fetch create topic:", error);
        }
    }

    const updateTopicTranslate = async () =>{
        try {
            const response = await axiosInstance.patch(`/admin/topicTranslate/${topicTranslate.id}`, topicTranslate);
            setTopicTranslateAfterUpdate(response.data)
            toast.success("Topic Translate is updated successfully");

        } catch (error) {
            toast.error("Failed to fetch update topic translate: "+ JSON.stringify(error.response.data));
            console.error("Failed to fetch update topic:", error);
        }
    }

    const deleteTopicTranslate = async () =>{
        try {
            const response = await axiosInstance.delete(`/admin/topicTranslate/${topicTranslate.id}`);
            setTopicTranslateAfterDelete(topicTranslate)
            toast.success("Topic Translate is deleted successfully");
        } catch (error) {
            toast.error("Failed to fetch delete topic translate: "+ JSON.stringify(error.response.data));
            console.error("Failed to fetch delete topic translate:", error);
        }
    }

    const onClickSaveButton = async () => {
        if(topicTranslate.id){
            await updateTopicTranslate()
        }else{
            await createTopicTranslate()
        }
    }

    const onClickDeleteButton = async () => {
        await deleteTopicTranslate()
    }


    return (
        <div className="d-flex w-100">
            {!editable &&
                <Link to={'/admin/topicTranslate/view'} className="btn btn-outline-warning rounded-0" >New</Link>
            }
            {topicTranslate && !editable &&
                <Link to={`/admin/topicTranslate/view?id=${topicTranslate.id}`} className="btn btn-outline-warning rounded-0" >View</Link>
            }
            {topicTranslate && editable &&
                <button className="btn btn-outline-warning rounded-0" onClick={onClickSaveButton}>Save</button>
            }
            {topicTranslate &&
                <button className="btn btn-outline-warning rounded-0" onClick={onClickDeleteButton}>Delete</button>
            }
        </div>
    );
}

export default TopicTranslateNav;
