import Topic from "../topic/TopicClass";
import {Link, useSearchParams} from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axios";
import TopicTranslate from "./TopicTranslateClass";
import TopicTranslateNav from "./TopicTranslateNav";
import MDEditor from "@uiw/react-md-editor";


function TopicTranslateView() {
    const [searchParams] = useSearchParams();
    const [topicTranslate, setTopicTranslate] = useState(new Topic());
    const [suggestions, setSuggestions] = useState([]);
    const [selectedEntitySlug, setSelectedEntitySlug] = useState('');
    const id = searchParams.get("id");
    const entity_id = searchParams.get("entity_id");

    const fetchTopic = async (topicId) => {
        try {
            const response = await axiosInstance.get(`/admin/topic/${topicId}`);
            const item = response.data;
            if (item) {
                return new Topic(
                    item.id,
                    item.slug,
                    item.slug_path,
                    item.type,
                    item.parent_id,
                    item.updated_at,
                    item.created_at
                );
            }
        } catch (error) {
            console.error("Failed to fetch topic:", error);
            return null;
        }
    };

    const fetchTopicTranslate = async (topicTranslateId) => {
        try {
            const response = await axiosInstance.get(`/admin/topicTranslate/${topicTranslateId}`);
            const item = response.data;
            if (item) {
                return new TopicTranslate(
                    item.id,
                    item.entity_id,
                    item.language_code,
                    item.title,
                    item.content,
                    item.updated_at,
                    item.created_at,
                );
            }
        } catch (error) {
            console.error("Failed to fetch topic translate:", error);
            return null;
        }
    };

    useEffect(() => {
        const loadTopic = async () => {
            const newTopicTranslate =  await fetchTopicTranslate(id)
            if (newTopicTranslate !== null){
                setTopicTranslate(newTopicTranslate);
                if (newTopicTranslate.entity_id){
                    const entityTopic = await fetchTopic(newTopicTranslate.entity_id)
                    setSelectedEntitySlug(entityTopic.slug)
                }
            }
        };

        const loadEntity = async () => {
            const entityTopic = await fetchTopic(entity_id)
            if(entityTopic){
                setTopicTranslate((prevTranslate) => ({
                    ...prevTranslate,
                    entity_id: entityTopic.id,
                }));
                setSelectedEntitySlug(entityTopic.slug)
            }

        }
        if (id) {
            setSelectedEntitySlug(null)
            setSuggestions([])
            loadTopic();
        }else if(entity_id){
            loadEntity();
        }
    }, [id]);


    const fetchEntitySuggestions = async (query) => {
        try {
            if (query.length > 2) {
                const response = await axiosInstance.get(`/admin/topic?slug=${query}`);
                setSuggestions(response.data);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error("Failed to fetch entity suggestions:", error);
        }
    };

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        setTopicTranslate((prevTopic) => ({
            ...prevTopic,
            [field]: value,
        }));
    };


    const handleEntityChange = (e) => {
        const inputValue = e.target.value;
        setSelectedEntitySlug(inputValue);
        if (inputValue.length === 0) {
            setTopicTranslate((prevTopic) => ({
                ...prevTopic,
                entity_id: null,
            }));
        } else {
            const entity = suggestions.find((suggestion) => suggestion.slug_path === inputValue);

            if (entity) {
                console.log(entity.id, entity.id);
                setSelectedEntitySlug(entity.slug);
                setTopicTranslate((prevTopic) => ({
                    ...prevTopic,
                    entity_id: entity.id,
                }));
                setSuggestions([]);
            } else {
                fetchEntitySuggestions(inputValue);
            }
        }
    };

    const onDeleteTopicTranslate = (deletedTopicTranslate) =>{
        setTopicTranslate(new TopicTranslate());
    }


    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Topic Translate Details</h5>
                    <TopicTranslateNav topicTranslate={topicTranslate} editable={true} setTopicTranslateAfterCreate={setTopicTranslate} setTopicTranslateAfterUpdate={setTopicTranslate} setTopicTranslateAfterDelete={onDeleteTopicTranslate}/>

                    <div className="mb-3">
                        <label htmlFor="lang_code" className="form-label">Language Code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="lang_code"
                            value={topicTranslate.language_code || ''}
                            onChange={(e) => handleInputChange(e, 'language_code')}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={topicTranslate.title || ''}
                            onChange={(e) => handleInputChange(e, 'title')}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="entity_id" className="form-label">Entity</label>
                        <input
                            type="text"
                            className="form-control"
                            id="entity_id"
                            value={selectedEntitySlug || ''}
                            onChange={(e) => handleEntityChange(e)}
                            list="entitySuggestions"
                        />
                        {topicTranslate.entity_id && <Link to={`/admin/topic/view?id=${topicTranslate.entity_id}`}>View Entity</Link>}
                        <datalist id="entitySuggestions">
                            {suggestions.map((suggestion) => (
                                <option
                                    key={suggestion.id}
                                    value={suggestion.slug_path}>
                                    {suggestion.slug_path}
                                </option>
                            ))}
                        </datalist>
                    </div>

                </div>
                <div className="card-footer">
                    <div className="d-flex">
                        <div className="mb-3">
                            <label htmlFor="updated_at" className="form-label">Updated At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="updated_at"
                                value={topicTranslate.updated_at || ''}
                                readOnly
                            />
                        </div>

                        {/* Created At */}
                        <div className="mb-3">
                            <label htmlFor="created_at" className="form-label">Created At</label>
                            <input
                                type="text"
                                className="form-control"
                                id="created_at"
                                value={topicTranslate.created_at || ''}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className="mb-3">
                <label htmlFor="content" className="form-label">Content</label>
                <div data-color-mode="dark" >
                    <MDEditor
                        className="text-light"
                        id="content"

                        value={topicTranslate.content || ''}
                        onChange={(value) => setTopicTranslate((prevTopic) => ({
                            ...prevTopic,
                            content: value,
                        }))}
                    />
                </div>
            </div>
        </div>
    );
}

export default TopicTranslateView;
