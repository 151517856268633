import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {getI18n, setI18n, useTranslation} from "react-i18next";
import axiosInstance from "../../axios";
import {toast} from "react-toastify";
import tgBot from "../../tgBot";
import CopyLinkButton from "../components/CopyLinkButton";

function TopicNavItem({ node }) {
    const topicItem = node.topic.topic;
    const hasChildren = node.child && node.child.length > 0;
    const collapseId = `collapse-${topicItem.id}`;

    return (
        <li className="ms-2 pb-1">
            {topicItem.type === "page" ?
                <Link to={`/topic/${topicItem.slug_path}`} className="fs-6 tg-text-color mb-2 rounded text-break">
                    {node.topic.data.title || topicItem.slug}
                </Link>
                :
                <button
                    className="fs-6 dropdown-toggle m-0 p-0 tg-text-color border-0 bg-secondary mb-2 rounded text-break"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded="false"
                >
                    {node.topic.data.title || topicItem.slug}
                </button>
            }

            {hasChildren && (
                <div className="collapse" id={collapseId}>
                    <ul className="list-unstyled pb-1">
                        {node.child.map((childNode) => (
                            <TopicNavItem node={childNode}/>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

function TopicNav({ tree }) {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false)
    const topicItem = tree.topic.topic;
    const hasChildren = tree.child && tree.child.length > 0;
    const collapseId = `collapse-${topicItem.id}`;
    const [languages, setLanguages] = useState([tree.topic.data.language_code])
    const [selectedLanguage, setSelectedLanguage] = useState(tree.topic.data.language_code)
    const location = useLocation();

    useEffect(() => {
        const GetTopicLanguages = async () =>{
            try {
                const response = await axiosInstance.get(`/topic/${topicItem.id}/lang`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setLanguages(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to load languages."));
            }
        }
        GetTopicLanguages();
    }, []);

    const onChangeLanguage = (e) => {
        setI18n(e.target.value)
    }

    return (
        <>
            <div className="position-sticky d-flex w-100 bg-body top-0 px-3">
                <button className="btn border tg-text-color fs-1" onClick={e => {setIsVisible(!isVisible)}}>
                    ≔
                </button>
                <div className="d-flex justify-content-end w-100 align-items-center">
                    <div className="align-content-center">
                        <select
                            className="form-select border-0"
                            id="language"
                            value={selectedLanguage}
                            onChange={(e) => onChangeLanguage(e)}
                        >
                            {languages && languages.map((lang)=>{
                                return <option value={lang}>{lang.toUpperCase()}</option>
                            })}
                        </select>
                    </div>

                </div>
            </div>
            {isVisible &&
                <>
                    <div className="w-100 h-100 position-fixed z-1 top-0" style={{ opacity: "0.5" }} onClick={e => {setIsVisible(!isVisible)}}>

                    </div>
                    <div className="flex-shrink-0 px-3 bg-secondary h-100 position-fixed z-2 top-0 rounded-end-4" style={{ width: "280px" }}>
                        <div className="d-flex justify-content-end border-bottom">
                            <div className="align-content-center text-break text-center w-100">
                                <Link to={`/topic/${topicItem.slug_path}`} className="tg-text-color fs-4 rounded">
                                    {tree.topic.data.title || topicItem.slug}
                                </Link>
                            </div>
                            <button className="btn tg-text-color fs-1" onClick={e => {setIsVisible(!isVisible)}}>
                                ✖
                            </button>
                        </div>

                        <div className="overflow-y-auto mt-2">
                            <ul className="list-unstyled pb-1">
                                {hasChildren &&
                                    tree.child.map((childNode) => (
                                            <TopicNavItem node={childNode}/>
                                        )
                                    )}
                            </ul>
                        </div>
                    </div>
                </>


            }

        </>

    );
}

export default TopicNav;
