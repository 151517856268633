import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios";
import TopicTranslate from "./TopicTranslateClass";
import TopicTranslateNav from "./TopicTranslateNav"; // Import your Axios instance if you have one

function TopicTranslateList({entity_id}) {
    const [topicTranslates, setTopicTranslates] = useState([]);
    const [selectedTopicTranslate, setSelectedTopicTranslate] = useState(null);

    useEffect(() => {
        const fetchTopicTranslates = async () => {
            try {
                const response = await axiosInstance.get(`/admin/topicTranslate?entity_id=${entity_id}`);
                const data = response.data.map(
                    (item) =>
                        new TopicTranslate(
                            item.id,
                            item.entity_id,
                            item.language_code,
                            item.title,
                            item.content,
                            item.updated_at,
                            item.created_at,
                        )
                );
                setTopicTranslates(data);
            } catch (error) {
                console.error("Failed to fetch topic translates:", error);
            }
        };

        fetchTopicTranslates();
    }, []);

    const onAfterDelete = (topicTranslate) =>{
        setTopicTranslates(topicTranslates.filter((item) => item.id !== topicTranslate.id))
        setSelectedTopicTranslate(null);
    }

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Topic Translate</h5>
                    <TopicTranslateNav topicTranslate={selectedTopicTranslate} editable={false} setTopicTranslateAfterDelete={onAfterDelete}/>
                    <table className="table table-dark overflow-x-auto">
                        <thead>
                        <tr>
                            <th scope="col">Language Code</th>
                            <th scope="col">Title</th>
                        </tr>
                        </thead>
                        <tbody>
                        {topicTranslates.length > 0 ? (
                            topicTranslates.map((topicTranslate) => (
                                <tr className={ selectedTopicTranslate && selectedTopicTranslate.id === topicTranslate.id && "table-info"} key={topicTranslate.id} onClick={(e) => setSelectedTopicTranslate(selectedTopicTranslate && selectedTopicTranslate.id === topicTranslate.id ? null: topicTranslate)}>
                                    <td>{topicTranslate.language_code}</td>
                                    <td>{topicTranslate.title}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No topic translates available
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TopicTranslateList;
