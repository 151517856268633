import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axios"; // Import your Axios instance if you have one
import Topic from "./TopicClass"
import TopicNav from "./TopicNav";


function TopicList() {
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await axiosInstance.get("/admin/topic");
                const data = response.data.map(
                    (item) =>
                        new Topic(
                            item.id,
                            item.slug,
                            item.slug_path,
                            item.type,
                            item.parent_id,
                            item.updated_at,
                            item.created_at
                        )
                );
                setTopics(data);
            } catch (error) {
                console.error("Failed to fetch topics:", error);
            }
        };

        fetchTopics();
    }, []);

    const onDeleteTopic = (deletedTopic) =>{
        setTopics(topics.filter((item) => item.id !== deletedTopic.id))
    }

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Topics</h5>
                    <TopicNav topic={selectedTopic} editable={false} setTopicAfterDelete={onDeleteTopic}/>
                    <table className="table table-dark overflow-x-auto">
                        <thead>
                        <tr>
                            <th scope="col">Slug</th>
                            <th scope="col">Slug Path</th>
                            <th scope="col">Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {topics.length > 0 ? (
                            topics.map((topic) => (
                                <tr className={ selectedTopic && selectedTopic.id === topic.id && "table-info"} key={topic.id} onClick={(e) => setSelectedTopic(selectedTopic && selectedTopic.id === topic.id ? null: topic)}>
                                    <td>{topic.slug}</td>
                                    <td>{topic.slug_path}</td>
                                    <td>{topic.type}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No topics available
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TopicList;
