class TopicTranslate{
    constructor(id,entity_id,language_code,title,content,created_at,updated_at) {
        this.id = id;
        this.entity_id = entity_id;
        this.language_code = language_code;
        this.title = title;
        this.content = content;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }
}

export default TopicTranslate;